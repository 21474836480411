import React from 'react';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';

type BasePagePropsType = {
  className?: string;
  location: {
    state: {
      formData: string | undefined;
    };
  };
};

const BasePage: React.FC<BasePagePropsType> = ({ className, location }) => {
  const data = (!!location.state && location.state.formData) ?? '';
  return (
    <div className={className}>
      <Layout>
        <div className="row">
          <div className="col-12">
            <h2>送信完了しました</h2>
            <span>控えとしてこの画面をコピー、またはスクショしてください</span>

            <div className="spacer30"></div>
            <div className="card bg-light">
              <div className="card-body">
                <span
                  style={{
                    margin: '10px 10px',
                    fontSize: '14px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {data}
                </span>
              </div>
            </div>

            <div className="spacer30"></div>
            <div className="card">
              <div className="card-body">
                <h5 style={{ marginTop: '30px', fontWeight: 600 }}>ご予約にあたって</h5>
                <ul style={{ margin: '30px 0', fontSize: '14px' }}>
                  <li>ご予約後、自動返信メールが届きます</li>
                  <li>
                    もしメールが届いていない場合、迷惑メールフォルダに入っていることがありますのでご確認ください
                  </li>
                  <li>受付順で、こちらから折り返しご連絡をいたします</li>
                  <li>いただきましたメール情報は、お得な情報を配信するために利用します</li>
                </ul>
              </div>
            </div>
            <div className="spacer30"></div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        h2 {
          margin: 30px 0;
          font-weight: 600;
        }
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default Page;
